import 'bootstrap';
import $ from 'jquery';
import { scrollAnchor } from './utils/scroll';

global.$ = global.jQuery = $;

require('jquery-nice-select');
require('jquery-nice-select/css/nice-select.css');

require('lightgallery.js/dist/css/lightgallery.min.css');
require('lightgallery.js');

require('../sass/page.scss');

require('slick-carousel');

$(document).on('click', 'a:not(#smweb-panel a)', scrollAnchor);

window.addEventListener('re-render', function () {

  document.querySelectorAll('.thumbs-gallery').
    forEach(gallery => lightGallery(gallery));
});

global.parseCoords = parseCoords;

function parseCoords(coords) {
  let x, y;
  const regex = /([0-9]+\.[0-9]+)N ?, ?([0-9]+\.[0-9]+)E/g;

  const f = coords.match(regex);
  if (null !== f) {

    [y, x] = f[0].split(',');

    x = x.trim().substring(0, x.length - 1);
    y = y.trim().substring(0, y.length - 1);

  } else {
    [x, y] = coords.split(',');
  }

  return [x, y]
}

function initVyletyMapa($holder) {

  let markers = $holder.data('marks');

  var center = SMap.Coords.fromWGS84(15.1033146, 50.7289462);
  var m = new SMap(JAK.gel("vylety"), center, 14);
  m.addDefaultLayer(SMap.DEF_BASE).enable();
  m.addDefaultControls();

  var layer = new SMap.Layer.Marker();
  m.addLayer(layer);
  layer.enable();

  let card;
  let options;
  let inner;
  markers.forEach(mark => {

    card = new SMap.Card();
    card.getHeader().innerHTML = `<strong>${mark.name}</strong>`;

    inner = '<ul style="margin-top: 1rem;">';
    mark.extra.forEach(e => {
      inner += `<li>${e}</li>`;
    });
    inner += '</ul>'

    card.getBody().innerHTML = inner + `<a href="${mark.link}">Zobrazit výlet</a>`;

    const [x, y] = parseCoords(mark.coords);
    center = SMap.Coords.fromWGS84(parseFloat(x), parseFloat(y));

    options = {
      title: mark.name
    };

    var marker = new SMap.Marker(center, mark.name + "_marker", options);
    marker.decorate(SMap.Marker.Feature.Card, card);
    layer.addMarker(marker);
  });
}

global.initVyletyMapa = initVyletyMapa;

function initAktivityMapa($holder) {

  let markers = $holder.data('marks');

  var center = SMap.Coords.fromWGS84(15.1033146, 50.7289462);
  var m = new SMap(JAK.gel("aktivityMapa"), center, 14);
  m.addDefaultLayer(SMap.DEF_BASE).enable();
  m.addDefaultControls();

  var layer = new SMap.Layer.Marker();
  m.addLayer(layer);
  layer.enable();

  let card;
  let options;
  markers.forEach(mark => {

    card = new SMap.Card();
    card.getHeader().innerHTML = `<strong>${mark.name}</strong>`;

    card.getBody().innerHTML = `<a href="${mark.link}">Zobrazit aktivitu</a>`;

    const [x, y] = parseCoords(mark.coords);
    center = SMap.Coords.fromWGS84(parseFloat(x), parseFloat(y));

    options = {
      title: mark.name
    };

    var marker = new SMap.Marker(center, mark.name + "_marker", options);
    marker.decorate(SMap.Marker.Feature.Card, card);
    layer.addMarker(marker);
  });
}

window.addEventListener('message', (event) => {
  if ((event.origin === location.origin) && event.data.context && (event.data.context === 'instagram')) {
    if (event.data.status === 'ok') {
      location.reload();
    } else {
      alert(event.data.message);
    }
  }
});

function ig_failed(target) {
  const client_id = $(target).data('client-id');
  const callbackUrl = $(target).data('callback');

  if (client_id && callbackUrl) {
    $(target).parent().append($('<button>', {
      text: 'Nepodařilo se načíst Instagram. Klikněte sem pro opětovnou autorizaci',
      click: function () {

        const
          width = 400,
          height = 500,
          left = (window.innerWidth / 2) - (width / 2),
          top = (window.innerHeight / 2) - (height / 2),
          params = `popup,scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${width},height=${height},left=${left},top=${top}`;

        window.open(`https://api.instagram.com/oauth/authorize?client_id=${client_id}&redirect_uri=${callbackUrl}&scope=user_profile,user_media&response_type=code`,
          'instagram',
          params);
      }
    }));
  }
}

global.initAktivityMapa = initAktivityMapa;

const limitedTexts = document.getElementsByClassName("text-limit");

for(const limitedText of limitedTexts) {
  const button = document.createElement("button");
  button.classList = "btn btn-link btn-text-limit";
  button.innerHTML = "Zobrazit více";
  button.onclick = () => {
    limitedText.classList.add("active");
    button.classList.add("hide");
  }

  limitedText.parentNode.insertBefore(button, limitedText.nextSibling ?? limitedText);
}



$(document).ready(function () {
  window.dispatchEvent(new CustomEvent('re-render'));
  $('[data-toggle="tooltip"]').tooltip();

  $('#toggle-menu').click(function() {
    $(this).toggleClass('active');
    $('ul.navbar-nav').toggleClass('opened');
  });

  $('[data-ig]').each(function () {
    const url = $(this).data('ig');
    const profile = $(this).data('igprofile')
    const _this = this;

    $.getJSON(url, function (data) {
      data.forEach(({ link, image, name }, i) => {
        if (i === data.length - 1) {
          link = profile;
        }
        $(_this).append(`<a href="${link}" target="_blank" class="s-item">
                            <img src="${image}" alt="${name}">
                            <div class="ico"></div>
                        </a>`);
        /*$(_this).append(`<img src="${image}" alt="${name}">`);*/
      });

      if(data.length === 0) {
        ig_failed(_this);
      }

      $('#instagram .social-hub').slick({
        fade: false,
        autoplay: false,
        slidesToShow: 3.5,
        infinite: false,
        prevArrow: $('.arrow-prev'),
        nextArrow: $('.arrow-next'),
        responsive: [
          {
            breakpoint: 2000,
            settings: {
              slidesToShow: 3.5
            }
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 2.8
            }
          },
          {
            breakpoint: 980,
            settings: {
              slidesToShow: 2.1
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1.6
            }
          }
        ]
      });
    }).fail(() => { ig_failed(_this) });
  });
});